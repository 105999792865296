import React from "react";
import NavBar from "./NavBar";
import Badge from "../elements/Badge";
import Resume from "../../resume.json";

function Hero() {
  return (
    <section className="hero is-dark is-fullheight has-background-link">
      <div className="hero-head">
        <NavBar />
      </div>
      <div className="hero-foot" style={{ paddingBottom: "20px" }}>
        <div className="columns is-mobile">
          <div className="column"></div>
          <div className="column"></div>
        </div>
      </div>
        <figure className="image container is-300x300">
          <img
            width="300px"
            height="300px"
            src={Resume.basics.picture}
            alt={Resume.basics.name}
            className="is-rounded"
            onError={(e)=>{e.target.onerror = null; e.target.src=Resume.basics.x_pictureFallback}}
          />
        </figure>
        <div className="spaceAbove"></div>
        <p className="subtitle is-4 has-text-white has-text-weight-bold">
          {Resume.basics.x_title}
        </p>
        <div className="subtitle is-5 has-text-white has-text-weight-light summary-text" dangerouslySetInnerHTML={{__html: Resume.basics.summary}}>
        </div>
        <div className="container interests">
          <div className="field is-grouped is-grouped-multiline has-text-centered">
            {Resume.interests.map((value, index) => {
              return (
                <Badge key={index} text={value.name} faIcon={value.x_icon} />
              );
            })}
          </div>
        </div>
        <div className="spaceBelow">
        <div className="spaceAbove"></div>
        <div className="columns is-centered">
          {Resume.basics.profiles.map((value, index) => {
            return (
              <div key={index} className="column has-text-centered is-narrow">
                <a
                  href={value.url}
                  target="blank"
                  className="is-hovered"
                  title={value.network}
                >
                  <span className="icon is-medium">
                    <i className={value.x_icon}></i>
                  </span>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Hero;
