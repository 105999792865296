import React from "react";
import TimelineItemEducation from "./TimelineItemEducation";
import TimelineHeader from "./TimelineHeader";
import Resume from "../../resume.json";

function Timeline() {
  return (
    <div className="timeline is-centered">
      {Resume.education
        .map(item => {
          return new Date(item.endDate).getFullYear();
        }).filter((value, index, self) => self.indexOf(value) === index)
        .map((year, i) => {
          let content = [];
          content.push(
            <TimelineHeader key={i} year={year}/>
          );
          content.push(
            Resume.education
              .filter(education => new Date(education.endDate).getFullYear() === year)
              .map((item, j) => {
                return (
                  <TimelineItemEducation
                    key={j}
                    date_start={new Date(item.startDate).toLocaleString("en-UK", {
                      month: "long",
                      year: "numeric"
                    })}
                    date_stop = {new Date(item.endDate).toLocaleString("en-UK", {
                      month: "long",
                      year: "numeric"
                    })}
                    studyType={item.studyType}
                    area={item.area}
                    website={item.website}
                    institution={item.institution}
                    score={item.score}
                    titleThesis={item.titleThesis}
                  />
                );
              })
          );
          return content;
        })}
    </div>
  );
}

export default Timeline;
