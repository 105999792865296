import React from "react";

function TimelineItemEducation(props) {
  return (
    <div className="timeline-item is-success">
      <div className="timeline-marker is-image is-32x32">
        <img src="" alt="" />
      </div>
      <div className="timeline-content">
        <p className="heading">{props.date_start} - {props.date_stop}</p>
        <h1 className="title is-4">{props.studyType} in {props.area}</h1>
        <h1 className="subtitle is-6"><a target="_blank" rel="noreferrer" href={`${props.website}`}>{props.institution}</a></h1>
        <p className="subtitle is-6" style={{ maxWidth: "25em" }}>{props.titleThesis}</p>
        <p className="subtitle is-6">Graduated with {props.score}</p>
      </div>
    </div>
  );
}

export default TimelineItemEducation;
