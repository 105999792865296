import React from "react";
import TimelineItem from "./TimelineItem";
import TimelineHeader from "./TimelineHeader";
import Resume from "../../resume.json";

function Timeline() {
  return (
    <div className="timeline is-centered">
      <header className="timeline-header">
        <span className="tag is-medium is-dark">
          {new Date().getFullYear()}
        </span>
      </header>
      <div className="timeline-item">
        <div className="timeline-marker is-success"></div>
        <div className="timeline-content"></div>
      </div>
      {Resume.work
        .map(item => {
          if (item.endDate === "Present") {
            return new Date().getFullYear();
          }
          else return new Date(item.endDate).getFullYear();
        }).filter((value, index, self) => self.indexOf(value) === index)
        .map((year, i) => {
          let content = [];
          content.push(
            <TimelineHeader key={i} year={year}/>
          );
          content.push(
            Resume.work
            .filter(work => {
              const endDate = work.endDate === "Present" ? new Date() : new Date(work.endDate);
              return endDate.getFullYear() === year;
            })
            .map((item, j) => {
              const startDate = new Date(item.startDate).toLocaleString("en-UK", {
                month: "long",
                year: "numeric"
              });
              
              const endDate = item.endDate === "Present" ? "Present" : new Date(item.endDate).toLocaleString("en-UK", {
                month: "long",
                year: "numeric"
              });
              
              return (
                <TimelineItem
                  key={j}
                  date_start={startDate}
                  date_stop={endDate}
                  company={item.company}
                  position={item.position}
                  website={item.website}
                  summary1={item.summary1}
                  summary2={item.summary2}
                  summary3={item.summary3}
                />
              );
            })
          );
          return content;
        })}
    </div>
  );
}

export default Timeline;
