import React from "react";

function Footer() {
  return (
    <footer className="footer-new has-background-link">
      <div className="content has-text-centered has-text-white image-container">
        <p>
        <a href="https://bulma.io/" target="_blank" rel="noopener noreferrer">
          <img
            width="256"
            height="48"
            src="https://versions.bulma.io/0.7.0/images/made-with-bulma--semiblack.png"
            className="bulma-image"
            alt=""
          />
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
