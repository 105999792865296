import React from "react";
import Skills from "../sections/Skills";
import Certifications from "../sections/Certifications";
import Experience from "../sections/Experience";
import Education from "../sections/Education"

function Content() {
  return (
    <main>
      <Experience />
      <Skills />
      <Certifications />
      <Education />
    </main>
  );
}

export default Content;
